@import url(https://fonts.googleapis.com/earlyaccess/opensanshebrew.css);
@import url(https://fonts.googleapis.com/earlyaccess/opensanshebrewcondensed.css);

.layoutpayment{
    position:relative;
    /* width: 1080px;
    height: 1920px; */
    background-image: url(./background.jpg);

}
.contentpayment{
    background-color: #ffffff;
    width: 913px;
    border-radius: 25px;
    filter: drop-shadow( 0px 4px 4px rgba(0, 0, 0, 0.25));

}
.fontSizepayment{

  font-family: 'Open Sans Hebrew';
  font-style: normal;
  font-weight: 700;
  src: url(//fonts.gstatic.com/ea/opensanshebrew/v3/OpenSansHebrew-Bold.eot);
  src: url(//fonts.gstatic.com/ea/opensanshebrew/v3/OpenSansHebrew-Bold.eot?#iefix) format('embedded-opentype'),
       url(//fonts.gstatic.com/ea/opensanshebrew/v3/OpenSansHebrew-Bold.woff2) format('woff2'),
       url(//fonts.gstatic.com/ea/opensanshebrew/v3/OpenSansHebrew-Bold.woff) format('woff'),
       url(//fonts.gstatic.com/ea/opensanshebrew/v3/OpenSansHebrew-Bold.ttf) format('truetype');
    font-size: 64px;
    line-height: 48px;
    color: #BF1E2D;
text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25), 0px 4px 4px rgba(0, 0, 0, 0.25);
}


.name_pack , .condition_pack {
    font-family: 'Open Sans Hebrew';
    font-style: normal;
    font-weight: 700;
    src: url(//fonts.gstatic.com/ea/opensanshebrew/v3/OpenSansHebrew-Bold.eot);
    src: url(//fonts.gstatic.com/ea/opensanshebrew/v3/OpenSansHebrew-Bold.eot?#iefix) format('embedded-opentype'),
         url(//fonts.gstatic.com/ea/opensanshebrew/v3/OpenSansHebrew-Bold.woff2) format('woff2'),
         url(//fonts.gstatic.com/ea/opensanshebrew/v3/OpenSansHebrew-Bold.woff) format('woff'),
         url(//fonts.gstatic.com/ea/opensanshebrew/v3/OpenSansHebrew-Bold.ttf) format('truetype');
}
.name_pack{
    text-align: left;
}



.condition_pack {
    text-align: right;
    display: flex;
    justify-content: flex-end;
  }
.searchcode{
    font-size: 24px;
    
}
.orderlistall{
    src: url(//fonts.gstatic.com/ea/opensanshebrew/v3/OpenSansHebrew-Bold.eot);
    src: url(//fonts.gstatic.com/ea/opensanshebrew/v3/OpenSansHebrew-Bold.eot?#iefix) format('embedded-opentype'),
         url(//fonts.gstatic.com/ea/opensanshebrew/v3/OpenSansHebrew-Bold.woff2) format('woff2'),
         url(//fonts.gstatic.com/ea/opensanshebrew/v3/OpenSansHebrew-Bold.woff) format('woff'),
         url(//fonts.gstatic.com/ea/opensanshebrew/v3/OpenSansHebrew-Bold.ttf) format('truetype');
    font-size: 24px;
    font-family: 'Open Sans Hebrew';
    font-style: normal;
    font-weight: 700;
    line-height: 20px;
    color: #9B9B9B;


}
.oderlistall{
    font-size: 24px;
}
.custom-search {
    font-size: 18px;
    height: 56px;
}

.custom-col-search{
    width: 294px;
}
.usepoint-fordiscountcss{
    padding-top: 25px;
    padding-bottom: 45px;
}
.promocodesearch{
    padding-top: 35px;
}
