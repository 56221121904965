@import url(https://fonts.googleapis.com/earlyaccess/opensanshebrew.css);
@import url(https://fonts.googleapis.com/earlyaccess/opensanshebrewcondensed.css);

.video-cover {
    text-align: center;
    height: 1920px; /* ความสูงเต็มหน้าจอ */
    width: 1080px;
    margin: 0;
    padding: 0;
    overflow: hidden; 
  }
  
  .video-background {
    position: relative;
    width: 100%;
    height: 100%;
    overflow: hidden;
  }
  
  .videoTag {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100%;
    height: 100%;
    object-fit: cover;
    transform: translate(-50%, -50%);
  }