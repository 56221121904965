  .adm-page-indicator {
    background-color: #F6F9FE;
    display: flex;
    width: auto;
    --dot-color: #aeaeae;
    --active-dot-color: var(--adm-color-primary);
    --dot-size: 10px;
    --active-dot-size: 23px;
    --dot-border-radius: 50px;
    --active-dot-border-radius: var(--dot-border-radius);
    --dot-spacing: 10px;
  }
  .adm-page-indicator-dot {
    display: block;
    width: var(--dot-size);
    height: var(--dot-size);
    border-radius: var(--dot-border-radius);
    background: var(--dot-color);
  }
  .adm-page-indicator-dot:last-child {
    margin-right: 0;
  }
  .adm-page-indicator-dot-active {
    background: #BF1E2D;
  }
  .adm-page-indicator-color-white {
    --dot-color: rgb(243 1 1 / 50%);
    --active-dot-color: var(--adm-color-white);
  }
  .adm-page-indicator-horizontal {
    flex-direction: row;
  }
  .adm-page-indicator-horizontal .adm-page-indicator-dot {
    margin-right: var(--dot-spacing);
  }
  .adm-page-indicator-horizontal .adm-page-indicator-dot-active {
    width: var(--active-dot-size);
  }
  .adm-page-indicator-vertical {
    flex-direction: column;
  }
  .adm-page-indicator-vertical .adm-page-indicator-dot {
    margin-bottom: var(--dot-spacing);
  }
  .adm-page-indicator-vertical .adm-page-indicator-dot-active {
    height: var(--active-dot-size);
  }
