  .card {
    width: 280px;
    height: auto;
    border: 2px solid #f8f9fd;
    transition: 1s all;
    text-decoration: none;
    position: relative;
    background: rgba(57, 57, 57, 0.7);
    display: inline-block;
    background-size: 200% auto;
    border: 1px solid;
    border-radius: 100px;
    padding: 10px 20px;
    font-size: 64px;
    text-transform: uppercase;
    box-shadow: 0 0 0 0 rgba(255, 0, 0, 0.7);
    animation: floating 3s ease-in-out infinite;
    transition: color 0.3s ease, box-shadow 0.3s ease, transform 0.3s ease; 
  }


  .icon-circle {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    justify-content: center;
    align-items: center;
    width: 80px;
    height: 80px;
    border-radius: 50%;
    background: #ffffff;
    box-shadow: 0px 6px 10px 0px rgba(255, 255, 255, 0.02);

  }
  .card:hover {
    background: #BF1E2D;
    transition: 0.4s all;
  }
  .card:hover .title,
  .card:hover .subtitle {
    color: #fff;
    transition: 0.4s all;
  }
  .btnRound {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    height: 30px;
    padding: 6px 28px;
    border-radius: 100px;
    border: 1px solid #BF1E2D;
    background: #fff;
    transition: all 0.2s ease;
  }
  .btnRound:hover {
    box-shadow: 0px 6px 10px 0px rgba(35, 197, 94, 0.2);
    transition: all 0.2s ease;
  }
  .btnService {
    position: absolute;
    bottom: -30px;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  
  .textuselanguage{
    color: #fff;
    font-style: normal;
    font-weight: 600;
    transition: 0.4s all;
    src: url(//fonts.gstatic.com/ea/opensanshebrew/v3/OpenSansHebrew-Bold.eot);
    src: url(//fonts.gstatic.com/ea/opensanshebrew/v3/OpenSansHebrew-Bold.eot?#iefix) format('embedded-opentype'),
         url(//fonts.gstatic.com/ea/opensanshebrew/v3/OpenSansHebrew-Bold.woff2) format('woff2'),
         url(//fonts.gstatic.com/ea/opensanshebrew/v3/OpenSansHebrew-Bold.woff) format('woff'),
         url(//fonts.gstatic.com/ea/opensanshebrew/v3/OpenSansHebrew-Bold.ttf) format('truetype');
    font-size: 24px;
    font-family: 'Open Sans Hebrew';
    font-style: normal;
    font-weight: 700;
    line-height: 20px;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25)) drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));


  }


  .card {
    padding: 17px 40px;
    border-radius: 30px;
    cursor: pointer;
    border: 0;
    /* background-color: white; */
    box-shadow: rgb(0 0 0 / 5%) 0 0 8px;
    letter-spacing: 1.5px;
    text-transform: uppercase;
    font-size: 15px;
    transition: all 0.5s ease;
  }
  
  .card:hover {
    letter-spacing: 3px;
    background-color: #BF1E2D(261deg 80% 48%);
    color: black;
    box-shadow: #BF1E2D 0px 7px 29px 0px;
  }
  
  .card:active {
    letter-spacing: 3px;
    background-color: #BF1E2D;
    box-shadow: #BF1E2D 0px 0px 0px 0px;
    transform: translateY(10px);
    transition: 100ms;
  }
  .alltexttouse{
    background:none;
    text-align: center;
  }

  .linestart{
    position: static;
    top: -50;
    border: 2px solid #BF1E2D;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    font-family: 'Open Sans Hebrew';
    font-style: normal;
    src: url(//fonts.gstatic.com/ea/opensanshebrew/v3/OpenSansHebrew-Bold.eot);
    src: url(//fonts.gstatic.com/ea/opensanshebrew/v3/OpenSansHebrew-Bold.eot?#iefix) format('embedded-opentype'),
         url(//fonts.gstatic.com/ea/opensanshebrew/v3/OpenSansHebrew-Bold.woff2) format('woff2'),
         url(//fonts.gstatic.com/ea/opensanshebrew/v3/OpenSansHebrew-Bold.woff) format('woff'),
         url(//fonts.gstatic.com/ea/opensanshebrew/v3/OpenSansHebrew-Bold.ttf) format('truetype');
    }

.linestart0{
  color: #fff;
  font-style: normal;
  font-weight: 300;
  transition: 0.4s all;
}

.title{
  color: #fff;
  font-size: 35px;
  font-style: normal;
  font-weight: 600;
  transition: 0.4s all;
}