.slidertop {
  position: relative;
  width: 100%;
  height: 100vh;
  overflow: hidden;
}

.slider-innertop {
  position: relative;
  width: 100%;
  height: 100%;
}

.slider-itemtop {
  position: absolute;
  width: 100%;
  height: 100%;
  opacity: 0;
  transition: opacity 1s ease-in-out;
}

.slider-itemtop.active {
  opacity: 1;
}

.slider-itemtop img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
