@import url(https://fonts.googleapis.com/earlyaccess/opensanshebrew.css);
@import url(https://fonts.googleapis.com/earlyaccess/opensanshebrewcondensed.css);


.packages{
height: 76px;
left: calc(50% - 354px/2 - 363px);
font-family: 'Open Sans Condensed';
font-style: Bold;
font-weight: 700;
font-size: 48px;
line-height: 0px;
align-items: center;
text-align: center;
color: #000000;
}

.vertical {
    border: 1.5px solid #B61F2D;
    height: 40px;     
    width: 2px;
    /* margin-top: 8px; */
}
