.laoutcontractscreen {
    padding: 25px;
    display: flex;
    flex-direction: column; 
    justify-content: space-between; 
    align-items: center; 
    border-bottom: 1px #af3033;

}

.toplayoutcontractscreen {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    width: 1080px; 
    height: 1920px; 
    background: transparent ;
    left: 50%;
    transform: translateX(-50%);
}

.divtocontractscreenbackgg{
    background-image: url(./background.jpg);    
    background-size: cover;

}

.HeaderScreentext{
    color: white;
    background-color:#af3033;
    font-family: 'Open Sans Hebrew';
    font-style: normal;
    font-weight: 700;
    src: url(//fonts.gstatic.com/ea/opensanshebrew/v3/OpenSansHebrew-Bold.eot);
    src: url(//fonts.gstatic.com/ea/opensanshebrew/v3/OpenSansHebrew-Bold.eot?#iefix) format('embedded-opentype'),
         url(//fonts.gstatic.com/ea/opensanshebrew/v3/OpenSansHebrew-Bold.woff2) format('woff2'),
         url(//fonts.gstatic.com/ea/opensanshebrew/v3/OpenSansHebrew-Bold.woff) format('woff'),
         url(//fonts.gstatic.com/ea/opensanshebrew/v3/OpenSansHebrew-Bold.ttf) format('truetype');
      font-size: 80px;
      line-height: 48px;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25), 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-top-left-radius: 30px;
  border-top-right-radius: 30px;
  padding-top: 37px;
  height: 110px;
}

.contentallcon{
    background-color: #ffffff;
    width: 913px;
    filter: drop-shadow( 0px 4px 4px rgba(0, 0, 0, 0.25));
}
.allleyouttuse{
    filter: drop-shadow( 0px 4px 4px rgba(0, 0, 0, 0.25));
}