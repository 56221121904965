.contain {
  margin: auto auto;
  padding: 2em 2em;
}
.section-wrapper {
  margin: 0 auto;
  border: 1px solid #e6e6e6;
  margin: 0px;
  padding: 8px 16px 8px 16px;
	border-radius: 0.3rem;
  box-shadow: 0px 2px 4px #e6e6e6;
}

.center-screen {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  min-height: 100vh;
}

.shadow {
  box-shadow: 0px 1px 2px #e6e6e6;
}

.primary-background {
  background: #AF3033;
  color: #fff;
}

.custom-menu-item > .ant-menu-item-selected {
  background-color: #fff !important;
  opacity: .5 !important;
  border-radius: 0px !important;
}

.custom-menu-item > .ant-menu-item {
  border-radius: 0px !important;
}

.custom-menu-item > .ant-menu-item:hover:not(.ant-menu-item-selected):not(.ant-menu-submenu-selected) {
  color: #fff !important;
}

.ant-table-thead > tr > th {
  background-color:#AF3033 !important;
  color: #ffff !important;
 
}

.custom-addon > .ant-input-group > .ant-input-group-addon {
	background-color:#AF3033 !important;
	color: #fff !important;
}

.custom-addon-voucher > .ant-input-group > .ant-input-group-addon {
	background-color:#52C41A !important;
	color: #fff !important;
}

.custom-card-actions-red > .ant-card-actions {
	background: #ff4d4f !important;
}

.custom-card-actions-blue > .ant-card-actions {
	background: #4096ff !important;
}

.custom-card-actions-text > .ant-card-actions > li {
	color: #fff !important;
}

.custom-input-font-weight > .ant-input-group > .ant-input {
	font-weight: bold;
}

.custom-list-item-title > .ant-list-items > .ant-list-item > .ant-list-item-meta > 
.ant-list-item-meta-content >.ant-list-item-meta-title > h4 {
  margin-top: 0;
}

.ant-picker-calendar.ant-picker-calendar-full .ant-picker-calendar-date {
	padding: 4px 2px 0;
}

.custom-calendar-header .ant-picker-calendar-mode-switch {
  display: none !important;
	justify-content: flex-start;
}

.custom-calendar-header .ant-picker-calendar-header {
	justify-content: flex-start;
}
.custom-collapse-header > .ant-collapse-item >.ant-collapse-header {
  color: #fff;
}
.custom-content-box > .ant-collapse-content-box {
    padding: 16px 0;
}

.dropdown-date {
  gap: 8px;
  width: 100%;
}

.rsd__select-container-day {
  width: 30%;
}

.rsd__select-day{
  height : 32px;
  width: 100%;
  border: 1px solid #d9d9d9;
  border-radius: 6px;
  background-color: #fff;
  font-size: 14px;

}

.rsd__select-container-month {
  width: 35%;
}

.rsd__select-month{
  height : 32px;
  width: 100%;
  border: 1px solid #d9d9d9;
  border-radius: 6px;
  background-color: #fff;
  font-size: 14px;

}

.rsd__select-container-year {
  width: 35%;
}
.rsd__select-year{
  height : 32px;
  width: 100%;
  border: 1px solid #d9d9d9;
  border-radius: 6px;
  background-color: #fff;
  font-size: 14px;

}

.custom-card-body .ant-card-body {
	padding: 16px;
	padding-top: 8px;
}

/* Override FullCalendar */
.fc .fc-toolbar-title {
  font-size: 14px !important;
}

.fc .fc-button-primary {
  background-color: #AF3033 !important;
  border-color: #AF3033 !important;
}

.fc .fc-col-header-cell-cushion {
  color: #fff !important;
  font-weight: normal;
	padding: 8px 4px !important;
}

.fc .fc-scrollgrid-section-header>* {
  background: #AF3033;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}

.fc .fc-toolbar.fc-header-toolbar {
  margin-bottom: 8px !important;
}

.ant-form-item {
  margin-bottom: 16px !important;
}

.small-descriptions > .ant-descriptions-header {
  margin-bottom: 8px !important;
}

.font-weight-bold-input-group > .ant-input-group .ant-input {
  font-weight: bold;
}

.kiosk-purchase-title {
    top: 80px;
    font-family: 'Open Sans Hebrew';
    font-style: normal;
    font-weight: 600;
    font-size: 40px;
    line-height: 100%;
    background: none;
    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: -0.04em;
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.button-kiosk{
  top: -20;
  /* Auto layout */
  color: #f5f5f5;
  background: #BF1E2D;
  /* drop-shadow/button-primary */
  box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.043);
  border-radius: 56px;
  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 0;
}

.red-side-line{
  border: 2px solid #BF1E2D;
  transform: rotate(-90deg);
}

.custom-menu-item-kiosk > .ant-menu-item-selected {
  background-color: rgb(255, 242, 242) !important;
  /* opacity: .5 !important; */
  border: 1.5px solid #BF1E2D !important;
}

.custom-menu-item-kiosk > .ant-menu-item {
  border-radius: 0px !important;
  width: 340px;
  height: 70px;
  background: #FFFFFF;
  box-shadow: 0px 20px 35px rgba(0, 0, 0, 0.05);
  border-radius: 20px !important;
  margin-top: 10px !important;
}

.custom-menu-item-kiosk > .ant-menu-item:hover:not(.ant-menu-item-selected):not(.ant-menu-submenu-selected) {
  color: #fff !important;
}

.custom-close-icon-modal{
  position: absolute;
  right: 0.01px;
  color: #6b6b6b;
}

.content-waiting-kiosk{
position: relative;
width: 1080px;
height: 1920px;
background-image: url('/src/screens/kiosk/CssScreen/background.jpg') ;
}

.custom-space-align-block {
  flex: none;
  margin: 8px 4px;
  padding: 4px;
}

.custom-text-shadow{
  font-family: 'Open Sans Hebrew';
  font-style: normal;
  font-weight: 700;
  font-size: 64px;
  line-height: 100%;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.language-selector{
  position: absolute;
  width: 331px;
  height: 135px;
  left: 749px;
  top: 0px;
  background: #FFFFFF;
  border-radius: 0px 0px 0px 20px;
  font-size: 24px;
}

.button-activate{
  top: -20;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 6.4px 15px;
  gap: 10px;
  color: #f5f5f5;
  background: #BF1E2D;
  box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.043);
  border-radius: 56px;
  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 0;
}

.kiosk-activate-title{
  top: 80px;
  font-family: 'Open Sans Hebrew';
  font-style: normal;
  font-weight: 600;
  font-size: 40px;
  line-height: 100%;
  background: none;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: -0.04em;
}

.kiosk-payment-button{
  box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.243);
}

.custom-spin{
  background-color: rgba(255, 255, 255, 1); /* Semi-transparent white */
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  max-height: 1920px !important;
  display: flex;
  align-items: center;
  justify-content: center;
}