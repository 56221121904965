

.button-17 {
    z-index: 6;
    align-items: center;
    appearance: none;
    background-color: #BF1E2D;
    box-shadow: rgba(0, 0, 0, .2) 0 3px 5px -1px,rgba(0, 0, 0, .14) 0 6px 10px 0,rgba(0, 0, 0, .12) 0 1px 18px 0;
    box-sizing: border-box;
    color: #F6F9FE;
    cursor: pointer;
    display: inline-flex;
    fill: currentcolor;
    font-family: "Google Sans",Roboto,Arial,sans-serif;
    font-size: 22px;
    font-weight: 500;
    height: 100px;
    justify-content: center;
    letter-spacing: .25px;
    line-height: normal;
    max-width: 100%;
    overflow: visible;
    padding: 2px 24px;
    position: relative;
    text-align: center;
    text-transform: none;
    transition: box-shadow 280ms cubic-bezier(.4, 0, .2, 1),opacity 15ms linear 30ms,transform 270ms cubic-bezier(0, 0, .2, 1) 0ms;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    width: auto;
    will-change: transform,opacity;
    display: flex;

    width: 270px; /* ขยายขนาดความกว้าง */
    height: 100px; /* ขยายขนาดความสูง */
    background: #BF1E2D;
    color: #ffffff;
    justify-content: center;
    align-items: center;
    border-radius: 0px 50px 50px 0px ;
    position: fixed;
    bottom: 220px;
    text-align: center;
    z-index:5;
    left: 0;

  }
  
  .backgr{
    background-image: linear-gradient(rgba(0, 0, 0, 0.05), rgba(0, 0, 0, 0.05)), url('../CssScreen/background.jpg');
    /* background-color: rgb(245, 245, 245); */
  }

  