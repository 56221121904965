@import url(https://fonts.googleapis.com/earlyaccess/opensanshebrew.css);
@import url(https://fonts.googleapis.com/earlyaccess/opensanshebrewcondensed.css);
.packagesAll{
position: absolute;
top: 310px;
font-family: 'Open Sans Hebrew';
font-style: normal;
font-weight: 700;
font-size: 48px;
line-height: 100%;
align-items: center;
justify-content: center;
display: flex;
text-align: center;
letter-spacing: -0.04em;
text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
width: 60%;
}
.layoutStyle {
  background-image: url('../CssScreen/background.jpg');
  background-size:contain; 
}
.contentStyle {
  background-image: linear-gradient(rgba(0, 0, 0, 0.05), rgba(0, 0, 0, 0.05)), url('../CssScreen/background.jpg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover; /* เพื่อให้ภาพพื้นหลังครอบคลุมทั้งหมด */
}

.contentStyle::before {
  width: 100%;
  height: 100%;
  background: inherit;
  filter: contrast(300%);
  z-index: -1;
}

.backgheader{
  /* background-image: linear-gradient(rgba(0, 0, 0, 0.05), rgba(0, 0, 0, 0.05)), url('../CssScreen/background.jpg'); */
  background-image: linear-gradient(rgba(0, 0, 0, 0.05), rgba(0, 0, 0, 0.05)), url('../CssScreen/background.jpg');
  display: flex;
  align-items: center;

}