@import url("https://fonts.googleapis.com/css?family=Poppins:100,200,300,400,500,600,700,800,900");

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Poppins", sans-serif;
}

:root {
  --clr: #ffffff;
}

.navigation {
  display: flex;
  position: fixed;
  bottom: 12%;
  left: 50%; 
  transform: translateX(-50%);
  width: 431px; /* Set width */
  height: 100px; /* Set height */
  background: #BF1E2D;
  color: #ffffff;
  justify-content: center;
  align-items: center;
  border-radius: 50px;
  text-align: center;
  font-size: 20px;
}

.navigation ul {
  display: flex;
  width: auto; 
}

.navigation ul li {
  position: relative;
  list-style: none;
  width: 100px; 
  height: 100px; 
  z-index: 1;
}

.navigation ul li a {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  text-align: center;
  font-weight: 500;
  transition: all 0.5s ease; /* Added animation */
}

.navigation ul li a .icon {
  position: relative;
  display: block;
  line-height: 100px; /* Set line-height */
  font-size: 2em; /* Set font size */
  text-align: center;
  transition: transform 0.5s ease, color 0.5s ease; /* Added animation */
  color: var(--clr);
}

.navigation ul li.active a .icon {
  transform: translateY(-50px); /* Adjust for new size */
}


.navigation ul li a .text {
  position: absolute;
  color: var(--clr);
  font-weight: 400;
  font-size: 1em; /* Set font size */
  letter-spacing: 0.05em;
  transition: opacity 0.9s ease, transform 0.9s ease; /* Added animation */
  opacity: 0;
  transform: translateY(50px);
}

.navigation ul li.active a .text {
  opacity: 1;
  transform: translateY(20px);
}

.indicator {
  position: absolute;
  top: -50%;
  width: 100px; /* Set width */
  height: 100px; /* Set height */
  background: #BF1E2D;
  border-radius: 50%;
  border: 6px solid #ffffff;
  transition: transform 0.5s ease; /* Added animation */
}

.indicator::before {
  content: "";
  position: absolute;
  top: 50%;
  left: -22px;
  width: 20px;
  height: 20px;
  background: transparent;
  border-top-right-radius: 20px;
  box-shadow: 1px -10px 0 0 var(--clr);
}

.indicator::after {
  content: "";
  position: absolute;
  top: 50%;
  right: -22px;
  width: 20px;
  height: 20px;
  background: transparent;
  border-top-left-radius: 20px;
  box-shadow: -1px -10px 0 0 var(--clr);
}

@media (max-width: 768px) {
  .navigation {
    width: 100%;
    height: 80px;
    left: 0;
    top: 0;
  }

  .navigation ul li {
    width: 80px;
    height: 80px;
  }

  .indicator {
    width: 80px;
    height: 80px;
  }
}
