.PackAgesShow {
    background: #FFFFFF;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25), 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 30px;
    width: 820px;
    height: 500px;
  }
  .Pack-text-Box {
    width: 93%;
    height: 100%;
    margin-top: 25px;
    top: 25px;
    font-family: 'Open Sans Hebrew';
    font-style: normal;
    font-weight: auto;
    font-size: 40px;
    line-height: 100%;
    text-align: center;
    color: #BF1E2D;
    white-space: nowrap;        /* Prevents the text from wrapping */
    overflow: hidden;           /* Hides the overflowing text */
    text-overflow: ellipsis;  /* Shows "..." for truncated text */
  }
  .line {
    left: calc(50% - 784px/2 - 8px);
    top: 85px;
    border: 4px solid #BF1E2D;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  }
  .TRAINER, .START, .EXP, .Duration, .DATE-START, .DATE-END, .DAY, .USAGE, .BALANCE {
    font-family: 'Open Sans Hebrew';
    font-weight: 700;
    font-size: 40px;
    line-height: 100%;
    color: #BF1E2D; /* for labels */
  }
  
  
  .DATE-START, .DATE-END, .DAY, .USAGE {
    font-weight: 400;
    color: #3E3935; /* for values */
  }

  .TRAINER {
    width: auto;
    height: 40px;
    left: 230px;
    top: 220px; /* ค่า top ที่ปรับแล้ว */
    text-align: right;
  }

  .BALANCE {
    width: auto;
    height: 40px;
    left: 245px;
    top: 275px; /* ค่า top ที่ปรับแล้ว */
    text-align: right;
  }

  .TRAINER-NAME {
    width: auto;
    height: 40px;
    left: 119px;
    top: 275px; /* ค่า top ที่ปรับแล้ว */
    text-align: right;
  }
.buttomnook {
  z-index: 6;
  align-items: center;
  appearance: none;
  background-color: #BF1E2D;
  box-shadow: rgba(0, 0, 0, .2) 0 3px 5px -1px,rgba(0, 0, 0, .14) 0 6px 10px 0,rgba(0, 0, 0, .12) 0 1px 18px 0;
  box-sizing: border-box;
  color: #F6F9FE;
  cursor: pointer;
  display: inline-flex;
  fill: currentcolor;
  font-family: "Google Sans",Roboto,Arial,sans-serif;
  font-size: 20px;
  font-weight: 500;
  height: 100px;
  justify-content: center;
  letter-spacing: .25px;
  line-height: normal;
  max-width: 100%;
  overflow: visible;
  padding: 2px 24px;
  position: relative;
  text-align: center;
  text-transform: none;
  transition: box-shadow 280ms cubic-bezier(.4, 0, .2, 1),opacity 15ms linear 30ms,transform 270ms cubic-bezier(0, 0, .2, 1) 0ms;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  width: auto;
  will-change: transform,opacity;
  display: flex;

  width: 140px; /* ขยายขนาดความกว้าง */
  height: 45px; /* ขยายขนาดความสูง */
  background: #BF1E2D;
}


.buttomOKandNO {
  z-index: 6;
  align-items: center;
  appearance: none;
  background-color: #BF1E2D;
  box-shadow: rgba(0, 0, 0, .2) 0 3px 5px -1px,rgba(0, 0, 0, .14) 0 6px 10px 0,rgba(0, 0, 0, .12) 0 1px 18px 0;
  box-sizing: border-box;
  color: #BF1E2D;
  cursor: pointer;
  display: inline-flex;
  fill: currentcolor;
  font-family: "Google Sans",Roboto,Arial,sans-serif;
  font-size: 20px;
  font-weight: 500;
  height: 100px;
  justify-content: center;
  letter-spacing: .25px;
  line-height: normal;
  max-width: 100%;
  overflow: visible;
  padding: 2px 24px;
  position: relative;
  text-align: center;
  text-transform: none;
  transition: box-shadow 280ms cubic-bezier(.4, 0, .2, 1),opacity 15ms linear 30ms,transform 270ms cubic-bezier(0, 0, .2, 1) 0ms;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  width: auto;
  will-change: transform,opacity;
  display: flex;

  width: 140px; /* ขยายขนาดความกว้าง */
  height: 45px; /* ขยายขนาดความสูง */
  background: #F6F9FE;
}
.swiperdot .adm-page-indicator {
  background-color: #001436;
  display: flex;
  width: auto;
  --dot-color: #aeaeae;
  --active-dot-color: var(--adm-color-primary);
  --dot-size: 10px;
  --active-dot-size: 23px;
  --dot-border-radius: 50px;
  --active-dot-border-radius: var(--dot-border-radius);
  --dot-spacing: 10px;
}
.customIndicator {
  position: fixed;
  z-index: 10;
  bottom: 10px; /* ตำแหน่งที่ล่างสุด */
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  align-items: center;
  justify-content: center;
  border: 0;
  background: rgba(191, 30, 45, 0.9);
  padding: 8px 18px;
  color: #ffffff;
  border-radius: 10px;
  user-select: none;
  font-size: 18px;
}

.Usage-Remain{
  font-family: 'Open Sans Hebrew';
  font-weight: 700;
  font-size: 40px;
  line-height: 100%;
  color: #BF1E2D;
}

.Usage-Remain {
  display: flex;
  flex-direction: column;
  justify-content: space-between; 
  height: 160px; 
  overflow: hidden;

}

.Usage-Remain .text-content {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2; 
  overflow: hidden; 
  text-overflow: ellipsis; 
}
.no-packages-message {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}
