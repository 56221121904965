.buttonvisaandmastercard , .buttonqrcode{
  width: 268px;
  height: 134px;
}
.buttonvisaandmastercard , .buttonqrcode {
  color: #090909;
  padding: 0.7em 1.7em;
  font-size: 20px;
  border-radius: 0.5em;
  background: #e8e8e8;
  cursor: pointer;
  border: 1px solid #e8e8e8;
  transition: all 0.3s;
  box-shadow: 6px 6px 12px #c5c5c5, -6px -6px 12px #ffffff;
}

.buttonvisaandmastercard:active {
  color: #666;
  box-shadow: inset 4px 4px 12px #c5c5c5, inset -4px -4px 12px #ffffff;
}
.buttonqrcode:active {
  color: #c5c5c5;
  box-shadow: inset 4px 4px 12px #c5c5c5, inset -4px -4px 12px #ffffff;
}
.laoutPayment{
  border-radius: 5em 5em 0em 0em ;
  box-shadow: 2px 2px 5px #dddddd, -2px -2px -5px #d8d8d8;
}
.TextChoosePaymentOption{
  src: url(//fonts.gstatic.com/ea/opensanshebrew/v3/OpenSansHebrew-Bold.eot);
  src: url(//fonts.gstatic.com/ea/opensanshebrew/v3/OpenSansHebrew-Bold.eot?#iefix) format('embedded-opentype'),
       url(//fonts.gstatic.com/ea/opensanshebrew/v3/OpenSansHebrew-Bold.woff2) format('woff2'),
       url(//fonts.gstatic.com/ea/opensanshebrew/v3/OpenSansHebrew-Bold.woff) format('woff'),
       url(//fonts.gstatic.com/ea/opensanshebrew/v3/OpenSansHebrew-Bold.ttf) format('truetype');
  font-size: 24px;
  font-family: 'Open Sans Hebrew';
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
  color: #383838;
}