@import url(https://fonts.googleapis.com/earlyaccess/opensanshebrew.css);
@import url(https://fonts.googleapis.com/earlyaccess/opensanshebrewcondensed.css);

.User-profile {
  position: relative;
  width: auto;
  height: 1920px;

  background-image: url(./background.jpg);
}

.id_user {
  /* padding-top: 100px; */
  font-family: 'Open Sans Hebrew';
  font-style: normal;
  font-weight: 700;
  font-size: 48px;
  line-height: 100%;
  /* or 48px */
  text-align: center;
  color: #BF1E2D;

}

.name_lastname {
  padding: 20px;
  font-family: 'Open Sans Hebrew';
  font-style: normal;
  font-weight: 700;
  font-size: 45px;
  line-height: 100%;
  /* or 45px */
  text-align: center;
  color: #3E3935;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.point-num,
.number-phone,
.mail-user {
  font-family: 'Open Sans Hebrew';
  font-style: normal;
  font-weight: 400;
  font-size: 48px;
  line-height: 100%;
  text-align: left;

  /* or 48px */
  color: #3E3A36;
}


.gift-icon,
.mail-icon,
.phone-icon {
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25)) drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
}

.button-17 {
  z-index: 5;
  color: #BF1E2D;
  background: #ffffff;
  font-size: 22px;
  height: 100px;
  width: 180px;
  border-radius: 0px 50px 50px 0px;
  position: fixed;
  left: 0;
  bottom: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  cursor: pointer;
  transition: background 280ms cubic-bezier(.4, 0, .2, 1), box-shadow 280ms cubic-bezier(.4, 0, .2, 1);
}

.contentalluser {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -60%);
}

.button-18 {
  z-index: 6;
  align-items: center;
  appearance: none;
  background-color: #BF1E2D;
  box-shadow: rgba(0, 0, 0, .2) 0 3px 5px -1px, rgba(0, 0, 0, .14) 0 6px 10px 0, rgba(0, 0, 0, .12) 0 1px 18px 0;
  box-sizing: border-box;
  color: #F6F9FE;
  cursor: pointer;
  display: inline-flex;
  fill: currentcolor;
  font-family: "Google Sans", Roboto, Arial, sans-serif;
  font-weight: 500;
  height: 100px;
  justify-content: center;
  letter-spacing: .25px;
  line-height: normal;
  max-width: 100%;
  overflow: visible;
  padding: 2px 24px;
  position: relative;
  text-align: center;
  text-transform: none;
  transition: box-shadow 280ms cubic-bezier(.4, 0, .2, 1), opacity 15ms linear 30ms, transform 270ms cubic-bezier(0, 0, .2, 1) 0ms;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  width: auto;
  will-change: transform, opacity;
  display: flex;

  width: 270px;
  /* ขยายขนาดความกว้าง */
  height: 100px;
  /* ขยายขนาดความสูง */
  background: #BF1E2D;
  color: #ffffff;
  justify-content: center;
  align-items: center;
  border-radius: 50px 0px 0px 50px;
  position: fixed;
  bottom: 220px;
  text-align: center;
  font-size: 22px;
  z-index: 5;
  right: 0;

}

.divpoints {
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.11));
}

.divpointstext {
  font-family: 'Open Sans Hebrew';
  font-style: normal;
  font-weight: 700;
  font-size: 39px;
  line-height: 100%;
  text-align: center;
}