.slider {
    position: fixed;
    width: 100%;
    height: 200px;
    left: 50%;
    transform: translateX(-50%);
    overflow: hidden;
    display: flex;
    align-items: center; /* Center align items vertically */
    justify-content: center; /* Center align items horizontally */
    bottom: 0;
  }
  
  .slider-inner {
    display: flex;
    transition: transform 0.5s ease-in-out;
    height: 100%; /* Ensure it takes the full height of the container */
  }
  
  .slider-item {
    min-width: 100%;
    box-sizing: border-box;
    height: 100%; /* Ensure it takes the full height of the container */
  }
  
  .slider-item img {
    width: 100%;
    height: 100%; /* Ensure the image takes the full height of the container */
    object-fit: cover; /* Cover the area, maintaining aspect ratio */
    display: block;
  }