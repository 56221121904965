.buttoncalsspackages{
    top: -20;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 6.4px 15px;
    gap: 10px;
    color: #f5f5f5;
    background: #BF1E2D;
    box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.043);
    /* border-radius: 56px; */
    flex: none;
    order: 0;
    align-self: stretch;
    flex-grow: 0;
}
.card-manu {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    background: #FFFFFF;
    border: 1px solid #868686;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 30px;
    flex: none;
    order: 0;
    flex-grow: 0;
}
.custom-description{
    height: 60;
}
.buttoncalsspackages {
    position: relative;
    padding: 10px 22px;
    border-radius: 50px;
    border: none;
    color: #fff;
    cursor: pointer;
    background-color: #BF1E2D;
    transition: all 0.2s ease;
    width: 160px;
    height: 50px;
  }