@import url(https://fonts.googleapis.com/earlyaccess/opensanshebrew.css);
@import url(https://fonts.googleapis.com/earlyaccess/opensanshebrewcondensed.css);

.login {
    text-align: center;
    height: 1920px; /* ความสูงเต็มหน้าจอ */
    width: 1080px;
    margin: 0;
    padding: 0;
    overflow: hidden; /* ป้องกันการเลื่อน */
    z-index: 4;
  }
  
  .videoTag {
    width: 100%;
    height: 100%;
    position:fixed;
    /* z-index: 3; */
  }
  .text-please{
    color: #3e3935;
    position: absolute;
    width: 1000px;
    height: 64px;
    left: 120px;
    top: 266px;
    font-family: 'Open Sans Hebrew';
    font-style: normal;
    font-size: 64px;
    line-height: 100%;
  }
  .text-qr{
    color: #3e3935;
    position: absolute;
    width: auto;
    height: 64px;
    left: 120px;
    top: 350px;
    font-family: 'Open Sans Hebrew';
    font-style: normal;
    font-size: 64px;
    line-height: 100%;
  }

  .content {
    position: relative;
    z-index: 5;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    flex-direction: column;
    text-align: left;
  }

  .qrcodelogin{
    position:fixed;
    z-index: 9;
  }
  .loginModalKiosk{
    background-color: aliceblue;
    border-radius: 30px;
    height: 500px;
    width: 500px;

  }

