.slidertop {
  height: 271px;
  width: 100%; /* เพิ่มความกว้างให้เต็มหน้าจอ */
  position:relative; /* หรือใช้ absolute ขึ้นอยู่กับความต้องการ */
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}

  
  .slider-innertop {
    display: flex;
    transition: transform 0.5s ease-in-out;
    height: 100%; 
  }
  
  .slider-itemtop {
    min-width: 100%;
    box-sizing: border-box;
    height: 100%; 
  }
  
  .slider-item img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    display: block;
  }